var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "client",
      "dataset-name": "time-tracker-payroll",
      "load-dictionaries": _vm.loadDictionaries,
      "show-labels": false,
      "compact": false,
      "loaded": false,
      "filters": _vm.filteringPanel.filters
    },
    on: {
      "change": _vm.onFilteringPanelChange,
      "search": _vm.onFilteringPanelSearch,
      "reset": _vm.onFilteringPanelReset
    }
  })], 1)], 1), _c('hr'), _vm.isLoading ? _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "light"
    }
  })], 1)])], 1) : _vm._e(), !_vm.isLoading ? _c('b-row', [_c('b-col', [_vm._l(_vm.report, function (user, index) {
    return _c('div', {
      key: "at-".concat(index)
    }, [_c('b-table', {
      attrs: {
        "fields": _vm.userTableColumns,
        "items": user.userTable.data,
        "dark": "dark",
        "table-variant": "dark",
        "head-variant": "dark",
        "responsive": ""
      }
    }), _vm._l(user.days, function (day, index2) {
      return _c('b-card', {
        key: "cd-".concat(index2),
        attrs: {
          "header-bg-variant": "transparent"
        }
      }, [_c('h5', {
        directives: [{
          name: "b-toggle",
          rawName: "v-b-toggle",
          value: "".concat(day.date),
          expression: "`${day.date}`"
        }],
        staticStyle: {
          "cursor": "pointer"
        }
      }, [day.status === _vm.DAY_STATUS.TRACKING ? _c('font-awesome-icon', {
        staticStyle: {
          "color": "lime"
        },
        attrs: {
          "icon": "clock"
        }
      }) : _vm._e(), day.status === _vm.DAY_STATUS.COMPLETED ? _c('font-awesome-icon', {
        attrs: {
          "icon": "check"
        }
      }) : _vm._e(), day.status === _vm.DAY_STATUS.FORGOTTEN ? _c('font-awesome-icon', {
        attrs: {
          "icon": "check"
        }
      }) : _vm._e(), day.status === _vm.DAY_STATUS.NOTCOMPLETED ? _c('font-awesome-icon', {
        staticStyle: {
          "color": "red"
        },
        attrs: {
          "icon": "exclamation-circle"
        }
      }) : _vm._e(), day.status === _vm.DAY_STATUS.SICK ? _c('font-awesome-icon', {
        attrs: {
          "icon": "temperature-high"
        }
      }) : _vm._e(), day.status === _vm.DAY_STATUS.VACATION ? _c('font-awesome-icon', {
        staticStyle: {
          "color": "gray"
        },
        attrs: {
          "icon": "plane"
        }
      }) : _vm._e(), day.status === _vm.DAY_STATUS.VACATION_APPROVED ? _c('font-awesome-icon', {
        staticStyle: {
          "color": "#4dbd74"
        },
        attrs: {
          "icon": "plane"
        }
      }) : _vm._e(), day.status === _vm.DAY_STATUS.VACATION_DECLINED ? _c('font-awesome-icon', {
        staticStyle: {
          "color": "red"
        },
        attrs: {
          "icon": "plane"
        }
      }) : _vm._e(), day.status === _vm.DAY_STATUS.HOLIDAY ? _c('font-awesome-icon', {
        attrs: {
          "icon": "home"
        }
      }) : _vm._e(), _vm._v(" " + _vm._s(day.date) + ", " + _vm._s(day.weekDay) + " ")], 1), _c('b-collapse', {
        staticClass: "mt-2",
        attrs: {
          "id": "".concat(day.date),
          "accordion": "my-accordion"
        }
      }, [_c('b-card', [_c('b-table', {
        attrs: {
          "fields": _vm.dayDetailsColumns,
          "items": day.details,
          "responsive": ""
        }
      })], 1)], 1), _c('hr'), _c('b-container', [_c('b-row', [_c('b-col', [_c('div', [_vm._v(" Total logged: " + _vm._s(day.totals.totalLoggedFormatted) + " ")]), _c('div', [_vm._v(" Total lunch: " + _vm._s(day.totals.totalLunchFormatted) + " ")]), _c('div', [_vm._v(" Total worked: "), day.totals.totalWorked >= 0 && day.totals.totalWorked <= 8 ? _c('span', [_vm._v(_vm._s(day.totals.totalWorkedFormatted))]) : _vm._e(), day.totals.totalWorked < 0 || day.totals.totalWorked > 8 ? _c('b-badge', {
        attrs: {
          "variant": 'danger'
        }
      }, [_vm._v(" " + _vm._s(day.totals.totalWorkedFormatted) + " ")]) : _vm._e()], 1)]), _c('b-col', [_c('div', [_vm._v(" Total sick paid: " + _vm._s(day.totals.totalSickPaidFormatted) + " ")]), _c('div', [_vm._v(" Total sick unpaid: " + _vm._s(day.totals.totalSickUnpaidFormatted) + " ")]), _c('div', [_vm._v("Total sick: " + _vm._s(day.totals.totalSickFormatted))]), _c('div', [_vm._v(" Total vacation: " + _vm._s(day.totals.totalVacationFormatted) + " ")])])], 1)], 1), _c('div', {
        staticClass: "ml-2"
      })], 1);
    })], 2);
  }), _vm.report.length > 0 ? _c('b-card', {
    attrs: {
      "bg-variant": "light"
    }
  }, [_c('b-card-text', [_c('h6', [_c('b-container', [_c('b-row', [_c('b-col', [_c('div', [_vm._v(" Total logged (h): " + _vm._s(_vm.reportTotal.totalLoggedFormatted) + " ")]), _c('div', [_vm._v(" Total lunch (h): " + _vm._s(_vm.reportTotal.totalLunchFormatted) + " ")]), _c('div', [_vm._v(" Total worked (h): " + _vm._s(_vm.reportTotal.totalWorkedFormatted) + " ")])]), _c('b-col', [_c('div', [_vm._v(" Total sick paid (h): " + _vm._s(_vm.reportTotal.totalSickPaidFormatted) + " ")]), _c('div', [_vm._v(" Total sick unpaid (h): " + _vm._s(_vm.reportTotal.totalSickUnpaidFormatted) + " ")]), _c('div', [_vm._v(" Total sick (h): " + _vm._s(_vm.reportTotal.totalSickFormatted) + " ")]), _c('div', [_vm._v(" Total vacation (h): " + _vm._s(_vm.reportTotal.totalVacationFormatted) + " ")])])], 1)], 1)], 1)])], 1) : _vm._e()], 2)], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }