<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-body>
        <b-row>
          <b-col>
            <!-- datasetName="time-tracker-payroll"  the same name as in payroll report to have syncronized period -->
            <filtering-panel
              ref="filteringPanel"
              mode="client"
              dataset-name="time-tracker-payroll"
              :load-dictionaries="loadDictionaries"
              :show-labels="false"
              :compact="false"
              :loaded="false"
              :filters="filteringPanel.filters"
              @change="onFilteringPanelChange"
              @search="onFilteringPanelSearch"
              @reset="onFilteringPanelReset"
            />
          </b-col>
        </b-row>
        <hr />
        <b-row v-if="isLoading">
          <b-col>
            <div class="text-center">
              <b-spinner variant="light" />
            </div>
          </b-col>
        </b-row>
        <b-row v-if="!isLoading">
          <b-col>
            <div v-for="(user, index) in report" :key="`at-${index}`">
              <b-table
                :fields="userTableColumns"
                :items="user.userTable.data"
                dark="dark"
                table-variant="dark"
                head-variant="dark"
                responsive
              />

              <b-card
                v-for="(day, index2) in user.days"
                :key="`cd-${index2}`"
                header-bg-variant="transparent"
              >
                <h5 v-b-toggle="`${day.date}`" style="cursor: pointer">
                  <font-awesome-icon
                    v-if="day.status === DAY_STATUS.TRACKING"
                    icon="clock"
                    style="color: lime"
                  />
                  <font-awesome-icon
                    v-if="day.status === DAY_STATUS.COMPLETED"
                    icon="check"
                  />
                  <font-awesome-icon
                    v-if="day.status === DAY_STATUS.FORGOTTEN"
                    icon="check"
                  />
                  <font-awesome-icon
                    v-if="day.status === DAY_STATUS.NOTCOMPLETED"
                    icon="exclamation-circle"
                    style="color: red"
                  />
                  <font-awesome-icon
                    v-if="day.status === DAY_STATUS.SICK"
                    icon="temperature-high"
                  />
                  <font-awesome-icon
                    v-if="day.status === DAY_STATUS.VACATION"
                    icon="plane"
                    style="color: gray"
                  />
                  <font-awesome-icon
                    v-if="day.status === DAY_STATUS.VACATION_APPROVED"
                    icon="plane"
                    style="color: #4dbd74"
                  />
                  <font-awesome-icon
                    v-if="day.status === DAY_STATUS.VACATION_DECLINED"
                    icon="plane"
                    style="color: red"
                  />
                  <font-awesome-icon
                    v-if="day.status === DAY_STATUS.HOLIDAY"
                    icon="home"
                  />

                  {{ day.date }}, {{ day.weekDay }}
                </h5>
                <b-collapse
                  :id="`${day.date}`"
                  accordion="my-accordion"
                  class="mt-2"
                >
                  <b-card>
                    <b-table
                      :fields="dayDetailsColumns"
                      :items="day.details"
                      responsive
                    />
                  </b-card>
                </b-collapse>

                <hr />
                <b-container>
                  <b-row>
                    <b-col>
                      <div>
                        Total logged: {{ day.totals.totalLoggedFormatted }}
                      </div>
                      <div>
                        Total lunch: {{ day.totals.totalLunchFormatted }}
                      </div>
                      <div>
                        Total worked:
                        <span
                          v-if="
                            day.totals.totalWorked >= 0 &&
                              day.totals.totalWorked <= 8
                          "
                          >{{ day.totals.totalWorkedFormatted }}</span
                        >
                        <b-badge
                          v-if="
                            day.totals.totalWorked < 0 ||
                              day.totals.totalWorked > 8
                          "
                          :variant="'danger'"
                        >
                          {{ day.totals.totalWorkedFormatted }}
                        </b-badge>
                      </div>
                    </b-col>
                    <b-col>
                      <div>
                        Total sick paid: {{ day.totals.totalSickPaidFormatted }}
                      </div>
                      <div>
                        Total sick unpaid:
                        {{ day.totals.totalSickUnpaidFormatted }}
                      </div>
                      <div>Total sick: {{ day.totals.totalSickFormatted }}</div>
                      <div>
                        Total vacation: {{ day.totals.totalVacationFormatted }}
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
                <div class="ml-2" />
              </b-card>
            </div>
            <b-card bg-variant="light" v-if="report.length > 0">
              <b-card-text>
                <h6>
                  <b-container>
                    <b-row>
                      <b-col>
                        <div>
                          Total logged (h):
                          {{ reportTotal.totalLoggedFormatted }}
                        </div>
                        <div>
                          Total lunch (h): {{ reportTotal.totalLunchFormatted }}
                        </div>
                        <div>
                          Total worked (h):
                          {{ reportTotal.totalWorkedFormatted }}
                        </div>
                      </b-col>
                      <b-col>
                        <div>
                          Total sick paid (h):
                          {{ reportTotal.totalSickPaidFormatted }}
                        </div>
                        <div>
                          Total sick unpaid (h):
                          {{ reportTotal.totalSickUnpaidFormatted }}
                        </div>
                        <div>
                          Total sick (h): {{ reportTotal.totalSickFormatted }}
                        </div>
                        <div>
                          Total vacation (h):
                          {{ reportTotal.totalVacationFormatted }}
                        </div>
                      </b-col>
                    </b-row>
                  </b-container>
                </h6>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { DAY_STATUS } from "@/shared/constants";

import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

import FilteringPanel from "@/components/FilteringPanel";

import { mapState } from "vuex";

export default {
  props: {
    mode: {
      type: String,
      default: ""
    }
  },
  components: {
    FilteringPanel
  },
  data: function() {
    return {
      DAY_STATUS: DAY_STATUS,
      isLoading: false,
      filteringPanel: {
        loaded: false,
        selected: {},
        filters: [
          {
            type: "select",
            dataType: "string",
            title: "User Name",
            tooltip: "User Name",
            name: "user_name",
            trackby: "id",
            label: "label",
            multiple: false,
            options: [],
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "Role",
            tooltip: "Role",
            name: "role",
            trackby: "id",
            label: "label",
            multiple: false,
            options: [],
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "Day Type",
            tooltip: "Day Type",
            name: "day_type",
            trackby: "id",
            label: "label",
            multiple: false,
            options: [
              {
                id: 2,
                label: "Forgotten day"
              },
              {
                id: 3,
                label: "Sick day"
              },
              {
                id: 4,
                label: "Vacation"
              },
              {
                id: 5,
                label: "Holiday"
              }
            ],

            selected: {}
          },
          {
            type: "daterange",
            dataType: "datetime",
            defaultRange: "This week",
            title: "Period",
            tooltip: "Select period",
            name: "period"
          }
        ]
      },
      rawData: {},
      report: [],
      reportTotal: {},
      userTableColumns: [
        {
          key: "user_id",
          label: "ID"
        },
        {
          key: "full_name",
          label: "Full name"
        },
        {
          key: "rolename",
          label: "Job role"
        }
      ],
      dayDetailsColumns: [
        {
          key: "action",
          label: "Action"
        },
        {
          key: "date_stamp",
          label: "Date"
        },
        {
          key: "time1",
          label: "From"
        },
        {
          key: "time2",
          label: "To"
        },
        {
          key: "location",
          label: "Location"
        },
        {
          key: "notes",
          label: "Notes"
        },
        {
          key: "status",
          label: "Status"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    })
  },
  mounted() {},
  methods: {
    async loadDictionaries() {
      let payload = {
        active: true
      };
      let users = await this.$api.post("users", payload);
      //let users = await this.$api.get(`role/H9/users`);

      let roles = await this.$api.get("roles", payload);

      //For Mike show just his department
      if (this.profile.data.role === "H9") {
        roles = roles.filter(u => u.roleid === "H9");
        users = users.filter(u => u["Role ID"] === "H9");
      }

      this.filteringPanel.filters.find(f => f.name === "user_name").options = [
        ...users.map(u => ({
          id: u.id,
          label: u.Name
        }))
      ];

      this.filteringPanel.filters.find(f => f.name === "role").options = [
        ...roles.map(u => ({
          id: u.roleid,
          label: u.rolename
        }))
      ];
    },
    getData: function() {
      let data = {
        period: this.filteringPanel.selected["period"]
      };

      return this.$api.post("timetracker/all-time", data);
    },
    drawFullReport() {
      let self = this;

      if (!this.rawData.length) return;

      this.report = [];

      let filteredData = this.rawData;

      if (this.filteringPanel.selected["user_name"])
        filteredData = filteredData.filter(
          s => s.user_id === this.filteringPanel.selected["user_name"].id
        );
      if (this.filteringPanel.selected["day_type"])
        filteredData = filteredData.filter(
          s => s.action === this.filteringPanel.selected["day_type"].label
        );
      if (this.filteringPanel.selected["role"])
        filteredData = filteredData.filter(
          s => s.rolename === this.filteringPanel.selected["role"].label
        );

      this.reportTotal = {
        totalLogged: 0,
        totalLoggedFormatted: "",
        totalWorked: 0,
        totalWorkedFormatted: "",
        totalLunch: 0,
        totalLunchFormatted: "",
        totalSick: 0,
        totalSickFormatted: "",
        totalSickPaid: 0,
        totalSickPaidFormatted: "",
        totalSickUnpaid: 0,
        totalSickUnpaidFormatted: "",
        totalVacation: 0,
        totalVacationFormatted: ""
      };

      let users = [...new Set(filteredData.map(a => a.user_id))];

      users.forEach(uid => {
        let user = {
          userTable: {
            data: []
          },
          days: []
        };

        //get user's details
        let u = filteredData.find(f => f.user_id === uid);

        user.userTable.data = [
          {
            user_id: u.user_id,
            full_name: u.full_name,
            rolename: u.rolename
          }
        ];

        //get user's timelog
        let data = filteredData.filter(f => f.user_id === uid);

        //get distinct dates
        let days = [...new Set(data.map(u => u.track_date))];

        //build daily report
        days.forEach(d => {
          let details = filteredData.filter(
            f => f.user_id === uid && f.track_date === d
          );

          let day = {};
          day.date = d;
          day.weekDay = moment(d).format("dddd");
          day.details = details;
          day.totals = self.calcDayTotals(details);
          day.status = this.getDayStatus(day);

          user.days.push(day);

          self.reportTotal.totalLogged += day.totals.totalLogged;
          self.reportTotal.totalLunch += day.totals.totalLunch;
          self.reportTotal.totalWorked += day.totals.totalWorked;
          self.reportTotal.totalSick += day.totals.totalSick;
          self.reportTotal.totalSickPaid += day.totals.totalSickPaid;
          self.reportTotal.totalSickUnpaid += day.totals.totalSickUnpaid;
          self.reportTotal.totalVacation += day.totals.totalVacation;
        });

        this.report.push(user);
      });

      this.reportTotal.totalLoggedFormatted = this.reportTotal.totalLogged.toFixed(
        2
      );
      this.reportTotal.totalLunchFormatted = this.reportTotal.totalLunch.toFixed(
        2
      );
      this.reportTotal.totalWorkedFormatted = this.reportTotal.totalWorked.toFixed(
        2
      );
      this.reportTotal.totalSickPaidFormatted = this.reportTotal.totalSickPaid.toFixed(
        2
      );
      this.reportTotal.totalSickUnpaidFormatted = this.reportTotal.totalSickUnpaid.toFixed(
        2
      );
      this.reportTotal.totalSickFormatted = this.reportTotal.totalSick.toFixed(
        2
      );
      this.reportTotal.totalVacationFormatted = this.reportTotal.totalVacation.toFixed(
        2
      );
    },
    getDayStatus(day) {
      let status = undefined;

      if (!day.details || day.details.length === 0) {
        return DAY_STATUS.NOTSTARTED;
      }

      let clockInCnt = day.details.filter(i => i.action === "Clock in").length;
      let clockOutCnt = day.details.filter(i => i.action === "Clock out")
        .length;
      let sickCnt = day.details.filter(i => i.action === "Sick day").length;
      let vacationCnt = day.details.filter(i => i.action === "Vacation").length;
      let vacationOnReview = day.details.filter(
        i => i.action === "Vacation" && i.approved === ""
      ).length;
      let vacationApproved = day.details.filter(
        i => i.action === "Vacation" && i.approved == "1"
      ).length;
      let vacationDeclined = day.details.filter(
        i => i.action === "Vacation" && i.approved == "0"
      ).length;
      let forgottenCnt = day.details.filter(i => i.action === "Forgotten day")
        .length;
      let holidayCnt = day.details.filter(i => i.action === "Holiday").length;

      if (holidayCnt > 0) {
        return DAY_STATUS.HOLIDAY;
      }
      if (forgottenCnt > 0) {
        return DAY_STATUS.FORGOTTEN;
      }
      if (sickCnt > 0) {
        return DAY_STATUS.SICK;
      }

      if (vacationCnt > 0 && vacationOnReview > 0) {
        return DAY_STATUS.VACATION;
      }
      if (vacationCnt > 0 && vacationApproved > 0) {
        return DAY_STATUS.VACATION_APPROVED;
      }
      if (vacationCnt > 0 && vacationApproved === 0 && vacationDeclined > 0) {
        return DAY_STATUS.VACATION_DECLINED;
      }

      if (clockInCnt === 0) {
        return DAY_STATUS.NOTSTARTED;
      }
      if (clockInCnt !== clockOutCnt) {
        return DAY_STATUS.NOTCOMPLETED;
      }
      if (clockInCnt > 0 && clockInCnt === clockOutCnt) {
        return DAY_STATUS.COMPLETED;
      }
      if (
        moment().isSame(day.day, "day") &&
        status === DAY_STATUS.NOTCOMPLETED
      ) {
        return DAY_STATUS.TRACKING;
      }
    },
    calcDayTotals(details) {
      let clockIn = 0,
        clockOut = 0,
        totalLogged = 0,
        totalWorked = 0,
        totalLunch = 0,
        totalSick = 0,
        totalSickPaid = 0,
        totalSickUnpaid = 0,
        totalVacation = 0;

      details.forEach(cell => {
        if (cell.action === "Clock in") {
          clockIn = cell.time_stamp;
        }

        if (cell.action === "Lunch") {
          totalLunch += moment.duration(
            moment(cell.time2, "HH:mm:ss").diff(
              moment(cell.time1, "HH:mm:ss"),
              "minutes",
              true
            )
          );
        }

        if (cell.action === "Clock out") {
          clockOut = cell.time_stamp;

          if (clockIn !== 0) {
            totalLogged += moment.duration(
              moment(clockOut).diff(moment(clockIn), "minutes", true)
            );
          }

          clockIn = 0;
          clockOut = 0;
        }
        if (cell.action === "Forgotten day") {
          totalLogged += moment.duration(
            moment(cell.time2, "HH:mm:ss").diff(
              moment(cell.time1, "HH:mm:ss"),
              "minutes",
              true
            )
          );
        }
        if (cell.action === "Holiday") {
          totalLogged += moment.duration(
            moment(cell.time2, "HH:mm:ss").diff(
              moment(cell.time1, "HH:mm:ss"),
              "minutes",
              true
            )
          );
        }
        if (cell.action === "Vacation" && cell.status === "Approved") {
          totalLogged += moment.duration(
            moment(cell.time2, "HH:mm:ss").diff(
              moment(cell.time1, "HH:mm:ss"),
              "minutes",
              true
            )
          );
          totalVacation += moment.duration(
            moment(cell.time2, "HH:mm:ss").diff(
              moment(cell.time1, "HH:mm:ss"),
              "minutes",
              true
            )
          );
        }
        if (cell.action === "Sick day") {
          totalLogged += moment.duration(
            moment(cell.time2, "HH:mm:ss").diff(
              moment(cell.time1, "HH:mm:ss"),
              "minutes",
              true
            )
          );
          //totalSick += moment.duration(moment(cell.time2, 'HH:mm:ss').diff(moment(cell.time1, 'HH:mm:ss'), 'minutes', true));
          totalSickPaid += parseFloat(cell.paid_hours) * 60;
          totalSickUnpaid += parseFloat(cell.unpaid_hours) * 60;
          totalSick +=
            (parseFloat(cell.paid_hours) + parseFloat(cell.unpaid_hours)) * 60;
        }
      });

      totalLogged = totalLogged || 0;

      totalWorked = totalLogged - totalLunch - totalSick - totalVacation;

      let total = {
        totalLogged: totalLogged / 60,
        totalLunch: totalLunch / 60,
        totalSick: totalSick / 60,
        totalSickPaid: totalSickPaid / 60,
        totalSickUnpaid: totalSickUnpaid / 60,
        totalVacation: totalVacation / 60,
        totalWorked: totalWorked / 60
      };

      (total.totalLoggedFormatted =
        (totalLogged >= 0 ? "" : "-") +
        moment
          .utc()
          .hours(Math.abs(totalLogged) / 60)
          .minutes(Math.abs(totalLogged) % 60)
          .format("HH:mm")),
        (total.totalLunchFormatted =
          (totalLunch >= 0 ? "" : "-") +
          moment
            .utc()
            .hours(Math.abs(totalLunch) / 60)
            .minutes(Math.abs(totalLunch) % 60)
            .format("HH:mm")),
        (total.totalWorkedFormatted =
          (totalWorked >= 0 ? "" : "-") +
          moment
            .utc()
            .hours(Math.abs(totalWorked) / 60)
            .minutes(Math.abs(totalWorked) % 60)
            .format("HH:mm"));
      total.totalSickFormatted = moment
        .utc()
        .hours(totalSick / 60)
        .minutes(totalSick % 60)
        .format("HH:mm");
      total.totalSickPaidFormatted = moment
        .utc()
        .hours(totalSickPaid / 60)
        .minutes(totalSickPaid % 60)
        .format("HH:mm");
      total.totalSickUnpaidFormatted = moment
        .utc()
        .hours(totalSickUnpaid / 60)
        .minutes(totalSickUnpaid % 60)
        .format("HH:mm");
      total.totalVacationFormatted = moment
        .utc()
        .hours(totalVacation / 60)
        .minutes(totalVacation % 60)
        .format("HH:mm");

      return total;
    },
    onFilteringPanelReset: function() {},
    onFilteringPanelChange: function(e) {
      this.filteringPanel.selected = e;

      if (this.filteringPanel.selected["period"].changed === true) {
        this.filteringPanel.selected["period"].changed = false;
      } else {
        this.drawFullReport();
      }
    },
    onFilteringPanelSearch: function(e) {
      let self = this;

      self.isLoading = true;

      this.filteringPanel.selected = e;

      this.getData().then(response => {
        self.isLoading = false;

        self.rawData = response;

        self.drawFullReport();
      });
    }
  },
  watch: {}
};
</script>
